import React, { useEffect, useRef } from "react";
import "./about.css";
const About = () => {
  return (
    <section className="about-section">
      <div>
        <div className="module-2">
          <div></div>
          <div className="about-container">
            <h1 className="about">About</h1>
            <p className="about-para">
              Hi, I'm Delaina!
              <br />
              <br />
              I am an Industrial Designer and multidisciplinary designer with
              over 2 years experience working on projects that have an impact on
              people and design systems. My areas of expertise include
              Industrial Design & 3D Product Visualization. <br /> <br />
              I started my career working as an intern at Forbes Marshall which
              is a leading provider of energy conservation and automation
              solutions for the process industry for over 75 years. I then
              proceeded to join Praan which is a venture-backed deep tech
              start-up. They use breakthrough patent-pending technology to
              address real climate change concerns by creating filter-less air
              purification devices that capture particulates and carbon dioxide
              from ambient air. This is when I realized how advanced technology
              may tackle real-world problems for future generations
              <br />
              <br />
              Currently, working as a Freelance Industrial & Visual Designer,
              focusing on bringing my creativity, visual aesthetic and problem
              solving mindset to clients and strengthen their business through
              Design.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About;
